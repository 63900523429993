body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #ccc;
  background-image: linear-gradient(315deg, #ccc 0%, #000 74%);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;

}

div {
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background-image: linear-gradient(315deg, #ccc 0%, #fff 74%);
  min-height: 100vh;

}
.container>div{

  margin: auto;
  text-align: center;

}
@media screen and (min-width: 700px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  ;
}
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #cfc;
  border-radius: 50px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}


input[type=text],
input[type=password],
input[type=email],
input[type=number] {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 15px;
  width: 100%;
  max-width: 600px;
  font-size: large;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  max-width: 100%;
  width: 100%;
}

textarea {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  width: 95%;
  max-width: 600px;
  font-size: 1.2em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: center;
  height: fit-content;
}

button {
  border: 2px outset #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;
  background-image: linear-gradient(315deg, #fff 0%, #ccc 74%);
  display: block;
  width: 100%;
  min-height: 35px;
  font-weight: 600;
  font-size: 1.2em;
}

button:hover {
  background-image: linear-gradient(315deg, #f90 0%, #ccc 95%);
  animation: gradient-animation 0.2s ease;
}

@keyframes gradient-animation {
  0% {
    background-image: linear-gradient(315deg, #f90 0%, #fff 30%, #ccc 74%);
  }
  50% {
    background-image: linear-gradient(315deg, #f90 0%, #ccc 75%);
   }
  100% {
    background-image: linear-gradient(315deg, #f90 0%, #ccc 85%) ;
  }
}

button:active {
  background-image: linear-gradient(315deg, #f90 0%, #ccc 95%);
  animation: gradient-animation 0.2s ease;
  border: 2px inset #ccc;
}

button:disabled {
  background-image: linear-gradient(315deg, #ccc 0%, #ccc 74%);
  animation: gradient-animation 0.5s ease;
}



.div_moreinfo {
  font-size: smaller;
  font-weight: normal;
  color: #000;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 600px;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px dashed #faa;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.h button {

  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 15px;
  width: 80%;
  max-width: 600px;
  font-size: 1em;

  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;

}

button.smallbutton {
  background-image: linear-gradient(315deg, #fff 0%, #ccc 74%);
  color: #000;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 15px;
  width: 50px;
  max-width: 100%;
  font-size: small;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;

}

button.smallbutton>i {
  font-size: 1em;
}


.input_title {
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
}

.receive_as {
  line-height: 60px;
}

#destination_addr {
  min-width: 300px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.loading_overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;


}

.loading_overlay>div {
  max-width: 600px;
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;

}

.loading_overlay img {
  max-width: 100%;
}

#errorbox{
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
  color: #000;
  font-weight: bold;
  
}

.info_msg {
  color: black;
  font-weight: bold;
  border: #0f0 1px solid;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  background-image: linear-gradient(315deg, #99ff99 0%, #ccffcc 74%);
  text-align: center;
  width: 100%;

}


.error_msg {
  color: red;
  font-weight: bold;
  border: #ff0000 1px solid;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  background-image: linear-gradient(315deg, #ff9999 0%, #ffcccc 74%);
}

.hid {
  display: none !important;
}

.hflex_whenwide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  min-width: fit-content;
  text-align: center;

}

.hflex_whenwide>div {
  flex: auto;
  text-align: center;

}

.step>div {
  text-align: center;
}

.vflex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  min-width: fit-content;
  text-align: center;

}

.vflex>div {
  flex: auto;
}

.dest_div {
  border: 3px dashed #fff;
}

.source_div {
  margin-top: 10px;
}

.transfer_type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

}

.transfer_type>div {
  display: block;
}

.transfer_type div>div {
  display: flex;
  margin: 5px;
  margin-top: 0;
  padding: 3px;
  /* border: 1px solid #fff;
  border-radius: 5px; */
  cursor: pointer;
  flex-flow: wrap;
}

.qr_reader_div {
  /* show on top of evertyhing */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  z-index: 999;

  overflow: hidden;
}

.qr_reader_div>section {
  width: 100%;
}

.qr_reader_div>section>div {
  margin-top: -10%;
}

.qr_reader_div>button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;

}

.div_qr {
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
  min-width: fit-content;
  height: 50%;
  border-radius: 10px;
  border: 1px solid #fff;


}

.div_qr>div {
  margin: 10px;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  font-size: large;

  display: flex;
  flex-direction: column;
  align-items: center;

}

.sharediv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  ;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.btn_share {
  background-image: linear-gradient(315deg, #fff 0%, #ccc 74%);
  color: #000;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 15px;
  width: 90%;
  max-width: 600px;
  font-size: normal;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;

}

.h {
  font-size: large;
  font-weight: bold;
}

.top_checks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

@media screen and (max-width: 700px) {
  .hflex_whenwide {

    flex-direction: column;

  }

  .transfer_type {
    flex-direction: column;
  }

  .hflex_whenwide>div {
    max-width: 100%;
  }

  .div_qr,
  .div_qr>div {
    width: 100%;
    font-size: 3.8vw;
    max-width: 100vw;
    padding: 0;
  }

  .input_title {
    height: fit-content;
    vertical-align: middle;
    line-height: inherit;
  }

  .receive_as {
    line-height: inherit;
  }

  .top_checks {
    flex-direction: row;
    display: flex;
  }

}

.div_transfer {
  max-width: 600px;
  margin: auto;
  width: fit-content;
}

#send_to_msg {
  text-align: left;
  padding: 5px;
  margin: 5px;
  margin-bottom: 0;
  padding-bottom: 0;
  width: 100%;
}

.fa {
  margin: 1px;
  font-size: 1.5em;
}

.btn_copy {
  max-width: 75vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 0px;
  width: 90%;
  max-width: 600px;
  font-size: large;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;
  background-image: linear-gradient(315deg, #fff 0%, #ccc 74%);

}

.mt {
  margin-top: 10px;
}

.mb {
  margin-bottom: 10px;
}

.nmb {
  margin-bottom: 0;
}

.nmt {
  margin-top: 0;
}